import { Component, OnInit } from "@angular/core";
import { MainService } from "../../providers/mainService";
import { FormGroup, FormControl, Validators } from "@angular/forms";

declare var $: any;
@Component({
  selector: "app-social-media",
  templateUrl: "./social-media.component.html",
  styleUrls: ["./social-media.component.css"],
})
export class SocialMediaComponent implements OnInit {
  constructor(private service: MainService) {}
  public link: string =
    "https://res.cloudinary.com/singhanurag400/image/upload/v1612535034/crxpef3en293n0as6gnj.jpg";
  ngOnInit() {}
}
