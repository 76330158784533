import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from 'src/app/providers/mainService';

@Component({
  selector: 'app-platform-add',
  templateUrl: './platform-add.component.html',
  styleUrls: ['./platform-add.component.css']
})
export class PlatformAddComponent implements OnInit {
  platformForm:FormGroup
  platformId:any
  flag : any
  constructor(private router:Router, public service: MainService, private spinner:NgxSpinnerService,private route : ActivatedRoute) { 
    this.route.queryParams.subscribe((res)=>{
      this.flag = res.flag 
     })
  }

  ngOnInit() {
    this.platformForm= new FormGroup({
      'title':new FormControl('',[Validators.required]),
      'description':new FormControl('',[Validators.required]),
      'descriptionView':new FormControl('',[Validators.required])

      
    })

  } 
  addPlatform(){
    let url ="static/addPlatform"
    let web_side ;
    if(this.flag == 1){
      web_side= 'Organiser' 
    }
    if(this.flag == 0){
      web_side= 'Player' 
    }
    if(this.flag == 2){
      web_side= 'NONE' 
    }
    let apiReqData={
      'title': this.platformForm.value.title,
      'descriptionView':this.platformForm.value.descriptionView,
      'description':this.platformForm.value.description,
      'web_side' : web_side
    }
    this.spinner.show()
    this.service.postApi(url,apiReqData,1).subscribe((res)=>{
    console.log(res);
    if(res.response_code==200){
      this.spinner.hide()
      this.service.showSuccess(res.response_message)
      this.router.navigate(["/platform-list"],{queryParams:{flag : this.flag}})
    }
    else{
      this.spinner.hide()
      this.service.toastErr(res.response_message)
    }
    },(err)=>{
      this.spinner.hide()
      this.service.toastErr(err.response_message)
    })
  }

}
