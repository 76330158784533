import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from 'src/app/providers/mainService';

@Component({
  selector: 'app-create-news',
  templateUrl: './create-news.component.html',
  styleUrls: ['./create-news.component.css']
})
export class CreateNewsComponent implements OnInit {
  createNews:FormGroup
  platformId:any
  flag : any
  typeofGame: any;
  id:any;
  type:any='CREATE'
  isViewFlag = false
  loadItemDetails: any;
  description: any;
  constructor(private router:Router, public service: MainService, private spinner:NgxSpinnerService,private route : ActivatedRoute) { 
    this.isViewFlag = false;
    this.onloadForm()
    this.route.queryParams.subscribe((res)=>{
      this.id = res.id 
      this.getViewItem(res.id)
     })
  }
  getViewItem(viewId){
    let usl = `newLetter/viewNewsLetter?id=${viewId}`
    this.service.getApi(usl,1).subscribe(respo=>{
      if(respo.responseCode==200){
         this.loadItemDetails = respo.result.mediaUrls[0].url,
         this.createNews.patchValue({
          typeofGame:respo.result.mediaTagTo,
          // image:respo.result.mediaUrls[0].url,
          title:respo.result.title,
          description:respo.result.description

         })
      }
    })
   }
   onloadForm(){
    this.createNews= new FormGroup({
      'title':new FormControl('',[Validators.required]),
      'description':new FormControl('',[Validators.required]),
      'image':new FormControl(''),
      'typeofGame':new FormControl('',Validators.required)
    })
   }
  ngOnInit() {
   
  } 

getRole(event){
  this.typeofGame = event.target.value;
}
competitionDetail: any=[];
membershipDetail: any=[];
venueDetail: any=[];
// Get CompetitionId Functionality

getCompetitionId(event){
  this.competitionDetail= this.comporgList.filter(x => x._id === event.target.value);
  console.log("this.competitionDetail---->>>> ",this.competitionDetail);
}
comporgList:any
getMembershipId(event){
  this.membershipDetail= this.memberlist.filter(x => x._id === event.target.value);
  console.log("this.memvershipDetail---->>>> ",this.membershipDetail);
}
getVenueId(event){
  this.venueDetail= this.venueList.filter(x => x._id === event.target.value);
  console.log("this.VenueDetail---->>>> ",this.venueDetail);
}
memberlist:any =[]
venueList:any=[]
memberList(){
//   var url = `membership/selectMembership?organizerId=`+this.userDetails._id;
//   this.service.getApi(url,1).subscribe(response => {
//    if(response.responseCode == 200) {
//     console.log(JSON.stringify(response));
//     this.memberlist = response.result;
//     console.log("member list--> ",this.memberlist);
//    } else if(response.responseCode == 402) {
     
//    }
//  });
}

// Get Venue List Functionality
getVenueFunc(){
//   var url = `venue/getVenueWithoutPagination?organizerId=`+this.userDetails._id;
//   this.service.getApi(url,1).subscribe(response => {
//    if(response.responseCode == 200) {
//     console.log(JSON.stringify(response));
//     this.venueList = response.result;
//     console.log("Venue list--> ",this.venueList);
//    } else if(response.responseCode == 402) {
     
//    }
//  });
}
  
  //  Get competition list Api
  getCompetitionListApi() {
    // return new Promise((resolve, reject) => {
    //   this.service.getApi('data/selectCompition?userId=' + this.userDetails._id, 1).subscribe(response => {
    //     if (response['responseCode'] == 200) {
    //       this.list.orgList = response[`result`]
          
    //       resolve(true)
    //     }
    //   })
    // })
  }
  submitNews(){
    var date = new Date();
    let url =`newLetter/createNewsFromAdmin?userId=${localStorage.getItem('adminId')}`

    let todayDate = (date.getMonth()+1)+'/'+date.getDate()+'/'+date.getFullYear();
    let time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    let apiReqData={
      'title': this.createNews.value.title,
      'description':this.createNews.value.description,
      'typeofGame':this.createNews.value.typeofGame,
      'image':[this.service.imageurlPlaceholder],
      'time': time,
      'date': todayDate,
      'status': "ACTIVE",
      'isAdmin':true,
    }
    this.service.postApi(url,apiReqData,1).subscribe((res)=>{
    console.log(res);
    if(res.responseCode==200){
      this.router.navigate(["/newsLater"])
      this.service.showSuccess("News Letter created")

    }
    else{
      this.spinner.hide()
      this.service.toastErr(res.responseMessage)
    }
    },(err)=>{
      this.spinner.hide()
      this.service.toastErr(err.responseMessage)
    })
  }
  fileChangeEvent(fileInput: any) {
    return new Promise((resolve, reject) => {
        if(fileInput.target.files && fileInput.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e: any) =>  {
              resolve(e)
            }
            reader.readAsDataURL(fileInput.target.files[0])
          }
    })

  }
  fileName: any = '';
  image: any = '';
  onFileChange(event) {
    this.fileName = event.target.value.split('\\').splice(-1)
    this.fileChangeEvent(event).then((res: any) => {
      this.image = res.target.result
    })
  }
}
