import { Component, OnInit } from '@angular/core';
import { MainService } from '../../providers/mainService';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any

@Component({
  selector: 'app-configurational',
  templateUrl: './configurational.component.html',
  styleUrls: ['./configurational.component.css']
})
export class ConfigurationalComponent implements OnInit {
  sms: FormGroup
  responseData: any;
  addMore: FormGroup
  addMoreTable: FormGroup
  noOfAddressField = 1
  smtp: FormGroup;
  paymentGateway: FormGroup;
  apiReqData: any = 'sms';
  formArrayData: any = [];
  confugureFieldType: any;
  configurePlayerList: any;
  configureOrganizeList: any = [];
  temp: any = []
  enableSubmitButton: any = 0;
  constructor(
    private service: MainService,
    private _FB: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  async ngOnInit() {
    await this.formArrayFunc()    //     FormArray Function
    await this.form()          //form  Function
    await this.getSMSData();
  }
  //....................................................................Form Function.....................................................................
  form() {
    this.smsForm();
    this.smtpForm();
    this.paymentGatewayForm();
  }
  //.......................................................................sms Tab //......................................................................
  smsForm() {
    this.sms = new FormGroup({
      auth_token: new FormControl('', [Validators.required]),
      s_Id: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
    })
  }
  smsTabFunction() {
    console.log(" SMS Tab ")
    this.getSMSData()
  }
  //......Get Sms OnPageLoad....
  getSMSData() {
    this.spinner.show()
      let apiData = {
      type: 'sms',
      search: ''
    }
    console.log("GetApi call of sms ")
    this.service.postApi('admin/getALL', apiData, 1).subscribe(success => {
      console.log(success)
      if (success.responseCode == 200) {
        if (success.result.length > 0) {
          if (success.result[0].type == 'sms') {
            this.responseData = success.result[0]
            this.sms.controls['auth_token'].setValue(this.responseData.auth_token)
            this.sms.controls['s_Id'].setValue(this.responseData.sid)
            this.sms.controls['number'].setValue(this.responseData.number)
          // } else if (success.result[0].type == 'smtp') {
            this.responseData = success.result[0]
            this.smtp.controls['SMTP_UserName'].setValue(this.responseData.smtpUsername)
            this.smtp.controls['SMTP_Password'].setValue(this.responseData.smtpPassword)
          // } else if (success.result[0].type == 'paymentGateway') {
            this.responseData = success.result[0]
            this.paymentGateway.controls['paymentG'].setValue(this.responseData.merchantId)
          }
        }
      } else if (success.responseCode == 409) {
        this.enableSubmitButton = 1
        this.service.toastErr(success.responseMessage)
      } else {
        this.service.toastErr(success.responseMessage)
      }
         this.spinner.hide();
    }, error => {
          this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
  //..........End Get Sms................

  //.........Add sms ............
  smsAdd(data) {
    console.log("SMS Add Function")
    let apidata = {
      "type": "sms",
      "sid": data.s_Id,
      "auth_token": data.auth_token,
      "number": data.number
    }
    console.log("apidata", apidata)
    this.spinner.show()
   
    this.service.postApi('admin/saveAll', apidata, 1).subscribe(success => {
      console.log(success)
      if (success.responseCode == 200) {
        this.service.showSuccess(success.responseMessage)
        this.router.navigate(['/dashboard'])
      } else {
        this.service.toastErr(success.responseMessage)
      }
          this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
  // .......end add sms.......

  // .......edit  sms........
  smsFunction(data: any) {
    console.log("Edit SMS Function")
    console.log("this.responseData._id,", this.responseData._id)
    let apidata = {
      "_id": this.responseData._id,
      "type": "sms",
      "sid": data.s_Id,
      "auth_token": data.auth_token,
      "number": data.number
    }
    console.log("apidata", apidata)
    this.spinner.show()
       this.service.postApi('admin/editAll', apidata, 1).subscribe(success => {
      console.log(success)
      if (success.responseCode == 200) {
        this.router.navigate(['/dashboard'])
        this.service.showSuccess(success.responseMessage)
      } else {
        this.service.toastErr(success.responseMessage)
      }
        this.spinner.hide();
    }, error => {
          this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
  //...................................................................end Sms Tab.......................................................................

  //...................................................................Smtp Tab..........................................................................
  smtpForm() {
    this.smtp = new FormGroup({
      SMTP_UserName: new FormControl('SMTP_UserName', [Validators.required]),
      SMTP_Password: new FormControl('SMTP_Password', [Validators.required]),
    })
  }
  smtpFunction(data: any) {
    this.spinner.show()
   
      let apidata = {
      "_id": this.responseData._id,
      "type": "sms",
      "smtpPassword": data.SMTP_Password,
      "smtpUsername": data.SMTP_UserName,
    }
      this.service.postApi('admin/editAll', apidata, 1).subscribe(success => {
         if (success.responseCode == 200) {
        this.router.navigate(['/dashboard'])
      } else {
        this.service.toastErr(success.responseMessage)
      }
      this.spinner.hide()
        }, error => {
         
          this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
  //...................................................................end Smtp Tab..........................................................................

  //...................................................................paymentGatewayForm Tab................................................................
  paymentGatewayForm() {
    this.paymentGateway = new FormGroup({
      paymentG: new FormControl('paymentGateway', [Validators.required]),
    })
  }
  paymentGatewayFunction(data: any) {
    console.log("paymentGatewayFunction", data)
    let apidata = {
      "_id": this.responseData._id,
      "type": "sms",
      "merchantId": data.paymentG,
    }
    this.spinner.show()
       this.service.postApi('admin/editAll', apidata, 1).subscribe(success => {
      console.log(success)
            this.spinner.hide();
      if (success.responseCode == 200) {
        this.router.navigate(['/dashboard'])
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
          this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })

  }
  //...................................................................end paymentGatewayForm Tab.................................................................

  //...................................................................End Form Function..........................................................................
  //...................................................................FormArray Function.........................................................................

  hideModal() {
    $('#logout1').modal('hide');
    for (let i = this.noOfAddressField; i > 0; i--) {
      this.removeInputField(i);
    }
    this.addMore.reset();
  }

  formArrayFunc() {
    this.addMore = new FormGroup({
      medi: this._FB.array([
        this.addAnotherField()
      ])
    })
  }

  addAnotherField() {
    return this._FB.group({
      labelName: new FormControl('', [Validators.required, Validators.pattern('[A-Za-z][A-Za-z \\s]*$')]),
      option: new FormControl('', [Validators.required])
    });
  }
  addNewInputField(ind): void {
    const control = <FormArray>this.addMore.controls.medi;
    control.push(this.addAnotherField());
    this.noOfAddressField++;
  }

  removeInputField(i: number): void {
    const control = <FormArray>this.addMore.controls.medi;
    control.removeAt(i);
    this.noOfAddressField--;
  }
  //..................................................Modal configure Field ..............................................................
  submitFunc(data: any) {
    this.formArrayData = []
    for (let i = 0; i < data.medi.length; i++) {
      this.formArrayData.push({ "label": data.medi[i].labelName, fieldType: data.medi[i].option, value: "optional" })
    }
    if (this.confugureFieldType == "organization") {                 // check   configured fields of type   organization 
      this.temp = this.formArrayData;
      if (this.configureOrganizeList.length != 0) {
        this.configureOrganizeList = this.configureOrganizeList.concat(this.temp)
      } else {
        this.configureOrganizeList = this.temp;
      }
    } else if (this.confugureFieldType == "player") {     // check   configured fields of type   player 
      this.temp = this.formArrayData;
      if (this.configurePlayerList.length != 0) {
        this.configurePlayerList = this.configurePlayerList.concat(this.temp)
      } else {
        this.configurePlayerList = this.temp;
      }
    }
    this.hideModal()   // clear modal fields and value
  }
  //.........................................................................................................................................

  //.............................................configure field function....................................................................   
  type(data) {
    if (data == "organization") {
      this.confugureFieldType = data
    } else if (data == "player") {
      this.confugureFieldType = data
    }
  }
  //..............................................save organizationFields Api function.......................................................
  organizationFields() {
    var req = {
      "type": "sms",
      "fieldName": this.configureOrganizeList
    }
    this.spinner.show()
    this.service.postApi(`admin/saveAll`, req, 1).subscribe(success => {
          this.spinner.hide();
      if (success.responseCode == 200) {
        this.service.showSuccess(success.responseMessage)
        this.getOrganizer()
      }
    }, error => {
      this.spinner.hide();
      this.service.toastErr(`something went wrong`)
    })
  }
  //............................................................................................................................................

  //................................................save playerFields Api function...............................................................
  playerFields() {
    this.spinner.show()
    var req = {
      "type": "sms",
      "fieldName": this.configurePlayerList
    }
    this.service.postApi(`admin/saveAll`, req, 1).subscribe(success => {
      this.spinner.hide();
      if (success.responseCode == 200) {
        this.service.showSuccess(success.responseMessage)
        this.getPlayer()
      }
      else{
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
      this.spinner.hide();
      this.service.toastErr(error.responseMessage)
    })
  }
  //.............................................................................................................................................

  //............................................................End Form Array Function..........................................................

  //.....................................................Registration Section On tab click ......................................................
  
  registrationFunc() {
    this.getPlayer()       //get player configured field
    this.getOrganizer()     //get organizer configured field
  }

  getPlayer() {
    let req = {
      type: "sms",
      search: ''
    }
    this.spinner.show()
   
    this.service.postApi(`admin/getALL`, req, 1).subscribe(success => {
       this.spinner.hide();
          if (success.responseCode == 200) {
        this.configurePlayerList = success.result[0].fieldName;                               //table data variable...
      } else if (success.responseCode == 409) {
        this.configurePlayerList = [];
      }
    }, error => {
        this.spinner.hide();
      this.service.toastErr(`something went wrong`)
    })
  }
  getOrganizer() {
    let req = {
      type: 'sms',
      search: ''
    }
    this.spinner.show()
      this.service.postApi(`admin/getALL`, req, 1).subscribe(success => {
           this.spinner.hide();
      if (success.responseCode == 200) {
        this.configureOrganizeList = success.result[0].fieldName;                               //table data variable...       
      } else if (success.responseCode == 409) {
        this.configureOrganizeList = [];
      }
    }, error => {
      this.service.toastErr(`something went wrong`)
    })
  }
}
