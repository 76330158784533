import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partneredit',
  templateUrl: './partneredit.component.html',
  styleUrls: ['./partneredit.component.css']
})
export class PartnereditComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
