import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { MainService } from '../../../providers/mainService';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-add-feature',
  templateUrl: './add-feature.component.html',
  styleUrls: ['./add-feature.component.css']
})
export class AddFeatureComponent implements OnInit {
  email: any
  imageurl: any =this.service.imageurlPlaceholder
  staticVideo: any
  image: any = ''
  id: any
  particularData: any
  constructor(
    private service: MainService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private ng2ImgMaxService: Ng2ImgMaxService
  ) { }

  ngOnInit() {
    this.form()
   
  }
//.........................Form Function ...................................
  form() {
    this.staticVideo = new FormGroup({
      title: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      descriptionView: new FormControl('', [Validators.required]),

      
    })
  }
   //...........................Base 64 function...........................................
  onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      let image = event.target.files[0];
      this.ng2ImgMaxService.resizeImage(image, 400, 300).subscribe(result => {
        var reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onload = (event: any) => {
          this.image = new Image();
          this.image.src = event.target.result;
          this.imageurl = event.target.result
          this.staticVideo.controls['file'].setValue(event.target.result)
        }
      }
      )
    }
  }
  //........................Feature  Add Function ...........................
  add(data) {
    let ApiData = {
      "image": data.file,
      "_id": this.id,
      "description": data.description,
      "title": data.title,
      "descriptionView":data.descriptionView,
    }
    this.spinner.show()
       this.service.postApiUpload(`admin/addFeature`, ApiData, 1).subscribe(response => {
               this.spinner.hide();
      if (response.responseCode == 200) {
        this.router.navigate(['/homePageEditFeatureList'])
      } else {
        this.service.toastErr(response.responseMessage)
      }
    }, error => {
           this.spinner.hide();
      this.service.toastErr("Something Went Wrong");
    })
  }
}
