import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { MainService } from '../../../../providers/mainService';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-comprehensive-feature-add',
  templateUrl: './comprehensive-feature-add.component.html',
  styleUrls: ['./comprehensive-feature-add.component.css']
})
export class ComprehensiveFeatureAddComponent implements OnInit {
  email: any
  imageurl: any =this.service.imageurlPlaceholder
  staticVideo: any
  image: any = ''
  id: any
  particularData: any
  url: any;
  currTab: any;
  buttonName: any;
  logoDetail: any;
  img: any;
  logoid: any;
  staticLogo: FormGroup;
  bannerDetail: any;
  staticBanner: FormGroup;
  bannerid: any;
  constructor(
    private service: MainService,
    private router: Router,
    private route: ActivatedRoute,
    private ng2ImgMaxService: Ng2ImgMaxService,
    private spinner: NgxSpinnerService,
  ) {
    this.route.params.subscribe(param => {
      this.id = param.id
      this.logoid = param.id
      this.bannerid = param.id
    })
    //-------------url managed for edit view ------------------//
    this.url = window.location.href;
    
  }

  ngOnInit() {
    this.form()
    
  }
  form() {
    this.staticVideo = new FormGroup({
      title: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      descriptionView: new FormControl(''),
    })
  }


  onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      let image = event.target.files[0];
      this.ng2ImgMaxService.resizeImage(image, 400, 300).subscribe(result => {
        var reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onload = (event: any) => {
          this.image = new Image();
          this.image.src = event.target.result;
          this.imageurl = event.target.result
        }
      }
      )
    }
  }
    //........................Service  add Function ...........................
    add(data) {
      let ApiData = {
        "image": this.imageurl,
        "description": data.description,
        "title": data.title
      }
      console.log(ApiData)
      this.spinner.show()
         this.service.postApiUpload(`admin/addCompFeature`, ApiData, 1).subscribe(response => {
                this.spinner.hide();
        if (response.responseCode == 200) {
          this.router.navigate(['/comprenhensiveFeatureList'])
        } else {
          this.service.toastErr(response.responseMessage)
        }
      }, error => {
              this.spinner.hide();
        this.service.toastErr("Something Went Wrong");
      })
    }



   //........................... upload image for logo Base 64 function...........................................
   onLogoImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      let image = event.target.files[0];
      this.ng2ImgMaxService.resizeImage(image, 400, 300).subscribe(result => {
        var reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onload = (event: any) => {
          this.image = new Image();
          this.image.src = event.target.result;
          this.imageurl = event.target.result
          // this.staticLogo.controls['file'].setValue(event.target.result)
        }
      }
      )
    }
  }


}
