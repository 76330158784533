import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { MainService } from '../../../../providers/mainService';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-banner-add',
  templateUrl: './banner-add.component.html',
  styleUrls: ['./banner-add.component.css']
})
export class BannerAddComponent implements OnInit {
  email: any
  imageurl: any=this.service.imageurlPlaceholder
  staticVideo: any
  image: any = ''
  id: any
  particularData: any
  constructor(
    private service: MainService,
    private router: Router,
    private route: ActivatedRoute,
    private ng2ImgMaxService: Ng2ImgMaxService,
    private spinner: NgxSpinnerService
  ) {
    this.route.params.subscribe(param => {
      this.id = param.id
    })
  }

  ngOnInit() {
      this.form()
    // this.getVideo()
  }
//.........................Form Function ...................................
  form() {
    this.staticVideo = new FormGroup({
      title: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
    })
  }
  //...........................get Particular Video List...................... 
  getVideo() {
    let apiData = {
      _id: this.id
    }
    this.spinner.show()
       this.service.postApi(`admin/particularList`, apiData, 1).subscribe(success => {
              this.spinner.hide();
      if (success.responseCode == 200) {
        this.particularData = success.result
        this.staticVideo.controls['title'].setValue(this.particularData.title)
        this.imageurl = this.particularData.image
        this.staticVideo.controls['description'].setValue(this.particularData.description)
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
          this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
  //...........................Base 64 function...........................................
  onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      let image = event.target.files[0];
      this.ng2ImgMaxService.resizeImage(image, 400, 300).subscribe(result => {
        var reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onload = (event: any) => {
          this.image = new Image();
          this.image.src = event.target.result;
          this.imageurl = event.target.result
          this.staticVideo.controls['file'].setValue(event.target.result)
        }
      }
      )
    }
  }
  //........................Service  Edit Function ...........................
  add(data) {
    let ApiData = {
      "image": data.file,
      "backGroungImage": data.file,
      "description": data.description,
      "title": data.title
    }
    if (data.file) {
      ApiData['image'] = data.file
    } else {
      ApiData['image'] = this.imageurl
    }
    this.spinner.show()
      this.service.postApiUpload(`admin/addBanner`, ApiData, 1).subscribe(response => {
              this.spinner.hide();
      if (response.responseCode == 200) {
        this.router.navigate(['/homePageServiceList'])
      } else {
        this.service.toastErr(response.responseMessage)
      }
    }, error => {
          this.spinner.hide();
      this.service.toastErr("Something Went Wrong");
    })
  }
}
