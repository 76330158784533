import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { MainService } from '../../../../providers/mainService';

@Component({
  selector: 'app-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.css']
})
export class BannerListComponent implements OnInit {
  VideoData: any = [{}]
  selectedAll: any
  constructor(
    private service: MainService,
    private router: Router,

  ) { }

  ngOnInit() {
    this.getList()
  }

  // selectAll() {
  //   if (this.selectedAll == true) {
  //     var selectedItem = []
  //     for (var i = 0; i < this.VideoData.length; i++) {
  //       this.VideoData[i].selected = this.selectedAll;
  //       selectedItem.push({ _id: this.VideoData[i]._id, selected: '' })
  //     }
  //     console.log("selected Array=====>>>>", selectedItem)
  //   } else {
  //     for (var i = 0; i < this.VideoData.length; i++) {
  //       this.VideoData[i].selected = this.selectedAll;
  //     }
  //   }
  // }
  selectAll() {
    for (var i = 0; i < this.VideoData.length; i++) {
      this.VideoData[i].selected = this.selectedAll;
    }
  }

  checkIfAllSelected() {
    this.selectedAll = this.VideoData.every(function (item: any) {
      return item.selected == true;
    })
  }

  getList() {
    this.service.getApi(`admin/bannerListing`, 1).subscribe(success => {
      if (success.responseCode == 200) {
        let result: any = []
        result = success.result
        for (let i = 0; i < result.length; i++) {
          result[i]['selected'] = false
        }
        this.VideoData = result
       
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
      this.service.toastErr("Something went wrong")
    })
  }

  send(){
 let selectEmail=[]
    for(let i=0;i<this.VideoData.length;i++){
      if(this.VideoData[i].selected){
        selectEmail.push({'_id':this.VideoData[i]._id})
      }
    }
    console.log('selectEmail===========>>>>>.',selectEmail)
  }
}
