import { Component, OnInit } from '@angular/core';
import { MainService } from '../../providers/mainService';
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $

@Component({
  selector: 'app-help-view',
  templateUrl: './help-view.component.html',
  styleUrls: ['./help-view.component.css']
})
export class HelpViewComponent implements OnInit {

  controls
  viewData: any;
  role: string;
  type: string = ''
  currId: string = '';
  platView: any;
  platId: any;
  buttonName: any;
  faq : any = [];
  faqId:any
  constructor(private service: MainService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router:Router) {
      let url =  window.location.href
      if(url.includes('Platform')){
        this.buttonName = 'plat'
        this.getPlatformData()
        console.log(this.getPlatformData())
      }else {
        this.buttonName = 'allData'
        //this.getTermsData()
      }
      this.route.queryParams.subscribe((res) => {
        this.faqId = res.id;
      })
     }

  ngOnInit() {

       this.getFaqList()
  }
  // getTermsData(){
  //   this.spinner.show()
  //   this.role = this.route.snapshot.paramMap.get('role');
  //   this.type = this.route.snapshot.paramMap.get('type');
  //   this.service.postApi(`terms/getTermsAndConditions`, { role: (this.role).toUpperCase() }, 1)
  //     .subscribe(response => {
  //       this.spinner.hide();
  //       if (response['responseCode'] == 200) {
  //         console.log("response data>>", response)
  //         this.viewData = response.result[this.type];
  //         this.viewData = this.sanitizer.bypassSecurityTrustHtml(this.viewData)
  //       }
  //       else {
  //         this.service.toastErr(response['responseMessage'])
  //       }
  //     }, error => {
  //       this.spinner.hide();
  //       this.service.toastErr('Something Went Wrong')
  //     })
  // }

  goBack() {
    window.history.back()
  }
  // 
  getPlatformData(){
    this.service.post(`static/staticContentList`,1).subscribe(response =>{
      if(response.response_code == 200){
        this.platView = response.result.docs[3].description
        console.log(this.platView)
        this.platId = response.result.docs[3]._id
      }

    },error =>{

    })

  }


   // ------- get FAQ list -------- //
   getFaqList() {
    this.faq=[]
    let url = "static/faqList"
       this.spinner.show()

    this.service.getApi(url,0).subscribe((response:any) => {
      if(response.response_code == 200){
        this.faq = response.result.docs;
        this.service.showSuccess(response['response_message'])
        this.spinner.hide();
      }
      else{
        this.spinner.hide();
        this.service.toastErr(response['response_message'])

      }
    })
}

// -------view faq-------
viewFaq(){
  let url = `static/viewFaq`
  this.spinner.show() 
  const reqData = {
    "faqId" : this.faqId
  }
  this.service.postApi(url,reqData,1).subscribe((response:any) =>{
    if(response.response_code == 200){
      this.spinner.hide();

      this.service.showSuccess(response['response_message'])

    }
    else{
      this.service.toastErr(response['response_message'])

     this.spinner.hide();

    }
  })
}
deleteId:any;
deleteComptition(deleteId){
this.deleteId = deleteId
$('#delete').modal('show');
}
delete(){
    let url = `static/deleteFaqs`
    let req= {faqId: this.deleteId}
    this.service.postApi(url,req,1).subscribe((response:any) => {
      if(response.response_code == 200){
       this.getFaqList()
        this.service.showSuccess('Data Delete')
        $('#delete').modal('hide');
      }
      else{
        $('#delete').modal('hide');

        this.service.toastErr(response['responseMessage'])

      }
    })
}

navigateEditfaq(id){
  this.router.navigate(["/faq-edit"],{queryParams:{itemId:id}})
}
}
