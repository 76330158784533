import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
@Injectable()
export class MainService {
  token: any;
  _id: any;
  adminId: any;
  baseUrl :any
  constructor(private http: HttpClient, private toastr: ToastrService) {
    this.baseUrl = environment.baseUrl
  }
  imageurlPlaceholder: any ='https://res.cloudinary.com/singhanurag400/image/upload/v1679046575/xvumvc1cyueofcyef199.png'
  typeLogin: string;
  postApi(url, data, isHeader): Observable<any> {
    if (isHeader == 0) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
      return this.http.post(this.baseUrl + url, data, httpOptions);
    } else if (isHeader == 1) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
          userId: localStorage.getItem("adminId"),
        }),
      };
      return this.http.post(this.baseUrl + url, data, httpOptions);
    }
  }
  //....................................VideoUpload Api......FormData.........

  postApiUpload(url, data, isHeader): Observable<any> {
    if (isHeader == 0) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          // "Content-Type": "multipart/form-data",
        }),
      };
      return this.http.post(this.baseUrl + url, data, httpOptions);
    } else if (isHeader == 1) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          // "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
          userId: localStorage.getItem("adminId"),
        }),
      };
      return this.http.post(this.baseUrl + url, data, httpOptions);
    }
  }
  //.................................End VideoUpload .................................................

  //....................................Message Api......FormData.........

  postApiMessage(url, data, isHeader): Observable<any> {
    if (isHeader == 0) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          // "Content-Type": "multipart/form-data",
        }),
      };
      return this.http.post(this.baseUrl + url, data, httpOptions);
    } else if (isHeader == 1) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          // "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
          userId: localStorage.getItem("adminId"),
        }),
      };
      return this.http.post(this.baseUrl + url, data, httpOptions);
    }
  }
  //.................................End message .................................................
  getApi(url, isHeader): Observable<any> {
    if (isHeader == 0) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
      return this.http.get(this.baseUrl + url, httpOptions);
    } else if (isHeader == 1) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
          userId: localStorage.getItem("adminId"),
        }),
      };
      return this.http.get(this.baseUrl + url, httpOptions);
    }
  }
  //-----------------------token in header for post API -----------------------//
  post(url, isHeader): Observable<any> {
    if (isHeader == 0) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
      return this.http.post(this.baseUrl + url, httpOptions);
    } else if (isHeader == 1) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        }),
      };
      return this.http.post(this.baseUrl + url, httpOptions);
    }
  }
  postPlatForm(url, data, isHeader): Observable<any> {
    if (isHeader == 0) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
      return this.http.post(this.baseUrl + url, data, httpOptions);
    } else if (isHeader == 1) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        }),
      };
      return this.http.post(this.baseUrl + url, data, httpOptions);
    }
  }
  //............... Toster function ................................
  showSuccess(msg) {
    this.toastr.success(msg);
  }
  toastErr(msg) {
    this.toastr.error(msg);
  }
  //................. End Toster Function............................
}
