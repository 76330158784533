import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from 'src/app/providers/mainService';

@Component({
  selector: 'app-clientadd',
  templateUrl: './partneradd.component.html',
  styleUrls: ['./partneradd.component.css']
})
export class PartneraddComponent implements OnInit {


  imageurl: any  =this.service.imageurlPlaceholder
  staticVideo: FormGroup
  image: any = ''
  id: any







  constructor(
    private service: MainService,
    private router: Router,
    private route: ActivatedRoute,
    private ng2ImgMaxService: Ng2ImgMaxService,
    private spinner: NgxSpinnerService,
  ) {
    this.route.queryParams.subscribe(param => {
      if(param.itemId){

        this.id = param.itemId
        this.getClient()
      }
    })

      this.form()
    
  }

  ngOnInit() {
    
  }
//.........................Form Function ...................................
  form() {
    this.staticVideo = new FormGroup({
      companyName: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
      companyUrl: new FormControl('', [Validators.required]),
    })
  }

  //...........................get Particular Video List...................... 
  getClient() {

    this.spinner.show()
      this.service.getApi(`partner/partnerDetail?_id=${this.id}`, 1).subscribe(success => {
               this.spinner.hide();
      if (success.responseCode == 200) {
        let data = success.result
        this.staticVideo.patchValue({
          companyName: data.companyName,
          companyUrl: data.companyUrl,
        })
        this.imageurl = data.imageUrl
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
           this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
  //...........................Base 64 function...........................................
  onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      let image = event.target.files[0];
      this.ng2ImgMaxService.resizeImage(image, 400, 300).subscribe(result => {
        var reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onload = (event: any) => {
          this.image = new Image();
          this.image.src = event.target.result;
          this.imageurl = event.target.result
        }
      }
      )
    }
  }
    //........................Service  add Function ...........................
    add() {
      let ApiData = {
        "companyName" :  this.staticVideo.value.companyName,
        "imageUrl" : this.imageurl,
        "companyUrl" : this.staticVideo.value.companyUrl
      }
      console.log(ApiData)
      this.spinner.show()
         this.service.postApiUpload(`partner/addPartner`, ApiData, 1).subscribe(response => {
                this.spinner.hide();
        if (response.responseCode == 200) {
          this.router.navigate(['/partner-list'])
        } else {
          this.service.toastErr(response.responseMessage)
        }
      }, error => {
              this.spinner.hide();
        this.service.toastErr("Something Went Wrong");
      })
    }

  //........................Service  Edit Function ...........................
  edit() {
    let ApiData = {
      "companyName" :  this.staticVideo.value.companyName,
      "imageUrl" : this.imageurl,
      "companyUrl" : this.staticVideo.value.companyUrl
    }
    
    this.spinner.show()
       this.service.postApiUpload(`partner/partnerEdit?_id=${this.id}`, ApiData, 1).subscribe(response => {
              this.spinner.hide();
      if (response.responseCode == 200) {
        this.router.navigate(['/partner-list'])
      } else {
        this.service.toastErr(response.responseMessage)
      }
    }, error => {
            this.spinner.hide();
      this.service.toastErr("Something Went Wrong");
    })
  }


}
