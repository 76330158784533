import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MainService } from '../../providers/mainService';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $
@Component({
  selector: 'app-news-later-list',
  templateUrl: './news-later-list.component.html',
  styleUrls: ['./news-later-list.component.css']
})
export class NewsLaterListComponent implements OnInit {
  codeCountryList: any
  competitionForm: any
  VideoData: any = [{}]
  selectedAll: any
  playerList: any;
  organizerList: any;
  searchData: any = {search: ''};
  filePath: any;
  fd: any;
  userForSendMail: any;
  deleteId: any;
  item1_email: any;
  item2_email: any;
  item3_email: any;
  constructor(
    private service: MainService,
     private spinner: NgxSpinnerService,
     public router : Router,
  ) {
   
  }

   ngOnInit() {
    this.form()
    this.getNewsList()
    this.getPlayerList()
    this.getOrg()
  }
  form() {
    this.competitionForm = new FormGroup({
      player: new FormControl(''),
      search: new FormControl(''),
      role: new FormControl(''),
      organizer: new FormControl('')
    })
  }

  search(data) {
    console.log("DATA=========...>>>", data)
    this.searchData = {search:data}
    this.getNewsList()
  }

  // getPlayer() {
  //   this.fd = new FormData();
  //   let apiData = {
  //     "role": 'PLAYER',
  //   }
  //   this.spinner.show()
  //      this.service.postApi(`admin/newsletterRole`, apiData, 1).subscribe(success => {
  //             this.spinner.hide();
  //     if (success.responseCode == 200) {
  //       this.playerList = success.result.docs
  //     } else {
  //       this.service.toastErr(success.responseMessage)
  //     }
  //   }, error => {
  //          this.spinner.hide();
  //     this.service.toastErr("Something went wrong")
  //   })
  // }

  // getOrganizer() {
  //   let apiData = {
  //     "role": 'ORGANIZER',
  //     "search": "",
  //     "orgId": "",
  //     "_id": ""
  //   }
  //   this.spinner.show()
  //     this.service.postApi(`admin/newsletterRole`, apiData, 1).subscribe(success => {
  //             this.spinner.hide();
  //     if (success.responseCode == 200) {
  //       this.organizerList = success.result.docs
  //     } else {
  //       this.service.toastErr(success.responseMessage)
  //     }
  //   }, error => {
  //         this.spinner.hide();
  //     this.service.toastErr("Something went wrong")
  //   })
  // }
  getNewsList() {
    this.spinner.show()
        this.service.postApi(`newLetter/listingNewsLetter`,{},1).subscribe(success => {
           this.spinner.hide();
          if (success.responseCode == 200) {
        let result: any = []
        result = success.result.docs
        for (let i = 0; i < result.length; i++) {
          result[i]['selected'] = false
        }
        this.VideoData = result
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
          this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
  // getList() {
  //   let apiData = {
  //     "role": this.searchData.role,
  //     "search": "",
  //     "orgId": "",
  //     "_id": ""
  //   }
  //   this.spinner.show()
  //       this.service.postApi(`admin/newsletterRole`, apiData, 1).subscribe(success => {
  //          this.spinner.hide();
  //         if (success.responseCode == 200) {
       
  //       let result: any = []
  //       result = success.result.docs
  //       for (let i = 0; i < result.length; i++) {
  //         result[i]['selected'] = false
  //       }
  //       this.VideoData = result
  //     } else {
  //       this.service.toastErr(success.responseMessage)
  //     }
  //   }, error => {
  //         this.spinner.hide();
  //     this.service.toastErr("Something went wrong")
  //   })
  // }
  //....................... check selected e-mail send  Function.......
  deleteIdDoc:any
  deletePopup(id){
    this.deleteIdDoc = id
    $('#newsletterdelete').modal('show')
// $('#delete').modal('show');

  }
  send() {
    if(!this.filePath){
      return this.service.toastErr("Please select attachment.")
    }
    let selectEmail = []
    for (let i = 0; i < this.VideoData.length; i++) {
      if (this.VideoData[i].selected) {
        selectEmail.push(this.VideoData[i].email)
      }
    }
    console.log('selectEmail===========>>>>>.', selectEmail)
    if(selectEmail.length==0){
      this.service.toastErr("Select atleast one user.")
      return
    }
    this.userForSendMail = selectEmail
    console.log(this.userForSendMail)
    this.fd = new FormData();
    this.fd.append('mailList', this.userForSendMail)
    this.spinner.show()
       this.service.postApiMessage(`admin/sendMailAll`, this.fd, 1).subscribe(success => {
              this.spinner.hide();
      if (success.responseCode == 200) {
        this.service.showSuccess(success.responseMessage)
                this.getNewsList();
        this.selectedAll=''
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
        this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
  //............................Select All Function ................
  selectAll() {
    for (var i = 0; i < this.VideoData.length; i++) {
      this.VideoData[i].selected = this.selectedAll;
    }
  }
  //..............................single select disselect..............
  checkIfAllSelected() {
    this.selectedAll = this.VideoData.every(function (item: any) {
      return item.selected == true;
    })
  }

  //createNewsFromAdmin
  //......................Particular user send mail funcion....................
isAllorg(){
  if(this.organizerList.length>0){
    let item  =[]
    this.organizerList.forEach(itm =>{
      item.push(itm.email)
    })
  return item
  }
}
isAllPlaye(){
  if(this.playerList.length>0){
    let item  =[]
    this.playerList.forEach(itm =>{
      item.push(itm.email)
    })
  return item
  }
}
isAllPlayerOrgnizer(){
  let newTotal  = this.playerList.concat(this.organizerList)
  if(newTotal.length>0){
    let item  =[]
    newTotal.forEach(itm =>{
      item.push(itm.email)
    })
  return item
  }
}
isroll(role){
 if(role =='ALL'){
  return this.isAllPlayerOrgnizer()
 }
 if(role =='ORGANIZER'){
   return this.isAllorg()
 }
 if(role =='PLAYER'){
  return this.isAllPlaye()
 }
}
  reciverUser(){
  this.item1_email =   this.competitionForm.value.player.email
  this.item2_email =   this.competitionForm.value.organizer.email
  this.item3_email = this.competitionForm.value.role

  }
  selectedItemForm(data){
    data['mailList']= []
    if(this.item1_email){
      data['mailList'].push(this.item1_email)
    }
    if(this.item2_email){
      data['mailList'].push(this.item2_email)
    }
    if(this.item3_email){
      let itemval = this.isroll(this.item3_email)
      if(itemval.length>0){
        itemval.forEach(em=>{
          data['mailList'].push(em)
        })
      }
    }
  return data
  }

  sendMail(data) {
    let  findata=[];
    // findata['Description'] =data.description
    // findata['Title'] =data.title
    // if(!this.image){
    //   return this.service.toastErr("Please select attachment.")
    // }
    let list = []
    let item  = this.selectedItemForm(findata)
    if(item.mailList.length==0){
      return this.service.toastErr("Please select user.")
    }
    console.log('All_item_mailList--->',data)
    this.fd = new FormData();
    // let emailtesting = ['ph-harikishan@mobiloitte.com']
    // this.fd.append('mailList', emailtesting)

    this.fd.append('mailList', item.mailList)
    this.fd.append('attachement', data.mediaUrls.length>0 ? data.mediaUrls[0].url :'')
    this.fd.append('description', data.description ? data.description :'')
    this.fd.append('title', data.title ? data.title :'')
    this.fd.append('userId', localStorage.getItem('adminId'))
 
    
    
    this.spinner.show()
       this.service.postApiMessage(`admin/sendMailAll`, this.fd, 1).subscribe(success => {
        this.spinner.hide();
      if (success.responseCode == 200) {
        this.service.showSuccess(success.responseMessage)
        this.getNewsList();
        this.selectedAll=''
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
       this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
  edit(id){
    this.router.navigate(['/create-news'],{queryParams:{id:id}})
  }
  viewDetails(id){
  this.router.navigate(['/view-news'],{queryParams:{id:id}})
  }
  //...............................delete user Function...................

  delete() {
     this.spinner.show()
       this.service.getApi(`newLetter/deleteNews?id=${this.deleteIdDoc}`, 1).subscribe(success => {
        this.spinner.hide();
      console.log("success=====>>delete",success)
      if (success.responseCode == 200) {
        this.service.showSuccess(success.responseMessage)
        this.getNewsList();
        this.selectedAll=''
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
          this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
//................File Select for Form Data....................................
  onUploadChange(evt: any) {
    const file = evt.target.files[0];
    console.log("Filename--->> ", file.name);
    this.forgot(file);
  }
  forgot(image) {
    if (image) {
      this.filePath = image
      this.fd.append('attachement', image);
    } else {
      this.fd.append('attachement', '');
    }
    console.log("ImageRequest--->>> ", this.fd)
  }

  getOrg() {
    let postData = {
      "role": 'ORGNIZER',
    }
    this.service.postApi(`users/userList`, postData, 1).subscribe(response => {
      if (response['responseCode'] == 200) {
         this.organizerList = response.result.docs
      }
    }, error => {
      this.spinner.hide()
      this.service.toastErr(`Something went wrong`)
    })

  }
 
  getPlayerList() {
    let postData = {
      "role": 'PLAYER',
    }
    this.service.postApi(`users/userList`, postData, 1).subscribe(response => {
      if (response['responseCode'] == 200) {
         this.playerList = response.result.docs
      }
    }, error => {
      this.spinner.hide()
      this.service.toastErr(`Something went wrong`)
    })

  }
  fileChangeEvent(fileInput: any) {
    return new Promise((resolve, reject) => {
        if(fileInput.target.files && fileInput.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e: any) =>  {
              resolve(e)
            }
            reader.readAsDataURL(fileInput.target.files[0])
          }
    })

  }
  fileName: any = '';
  image: any = '';
  onFileChange(event) {
    this.fileName = event.target.value.split('\\').splice(-1)
    this.fileChangeEvent(event).then((res: any) => {
      this.image = res.target.result
    })
  }

  splitItem(text){
    let splitText = text ? text.substring(0, 200) : '';
    return splitText
  }
  deleteNesitem(id){
  this.deleteId =id
  }
  deleteItem(){
    this.service.postApi(`newLetter/deleteNews?id=${this.deleteId}`, this.fd, 1).subscribe(success => {
    if (success.responseCode == 200) {
      this.service.showSuccess(success.responseMessage)
      this.getNewsList();
      this.selectedAll=''
    } else {
      this.service.toastErr(success.responseMessage)
    }
  }, error => {
     this.spinner.hide();
    this.service.toastErr("Something went wrong")
  })
  }

}
