import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../../../providers/mainService';
declare var $

@Component({
  selector: 'app-comprehensiv-feature',
  templateUrl: './comprehensiv-feature.component.html',
  styleUrls: ['./comprehensiv-feature.component.css']
})
export class ComprehensivFeatureComponent implements OnInit {
  featureData: any
  
  constructor(
    private service: MainService,
    private router: Router,
  ) { 
  }

  ngOnInit() {
    this.getList()
  }

  getList() {
    this.featureData =[]
    this.service.getApi(`admin/comprehensiveListing`, 1).subscribe(success => {
      console.log(success);
      
      if (success.responseCode == 200) {
        this.featureData = success.result
      } else {
        this.service.toastErr(success.responseMessage)
      }
      
    }, error => {
      this.service.toastErr("Something went wrong")
    })
  }
  deleteId:any;
deleteComptition(deleteId){
this.deleteId = deleteId
$('#delete').modal('show');
}
  delete(){
    let url = `admin/comprehensiveDelete`
    let req = {'_id':this.deleteId}
    this.service.postApi(url,req,1).subscribe((response:any) => {
      if(response.responseCode == 200){
       this.getList()
        this.service.showSuccess(response['responseMessage'])
        $('#delete').modal('hide');
      }
      else{
        $('#delete').modal('hide');
      }
    })
}
  
breakLine(text){
  return  text.slice(0, 10);
}
}
