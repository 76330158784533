import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from 'src/app/providers/mainService';
declare var $
@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit {

  page = 1
  limit = 10
  total : number

  faq : any = [];

  constructor(private service: MainService,

    private spinner: NgxSpinnerService,
 
    private router:Router) {
  
    
     }

  ngOnInit() {

       this.getFaqList()
  }


  goBack() {
    window.history.back()
  }




   // ------- get FAQ list -------- //
   getFaqList() {
    let url = "client/clientList"
       this.spinner.show()
       let data = {
        "page" : this.page,
        "limit" : this.limit
      
    }

    this.service.postApi(url,data,0).subscribe((response:any) => {
      if(response.responseCode == 200){
        this.faq = response.result.docs;
        this.total = response.result.total;
        this.service.showSuccess(response['responseMessage'])
        this.spinner.hide();
      }
      else{
        this.spinner.hide();
        this.service.toastErr(response['responseMessage'])

      }
    })


   
}




navigateEditfaq(id){
  this.router.navigate(["/client-edit"],{queryParams:{itemId:id}})
}
changePage($event){
      this.page = $event
      this.getFaqList()
}

deleteId:any;
deleteComptition(deleteId){
this.deleteId = deleteId
$('#delete').modal('show');
}
delete(){

    let url = `client/deleteClient?_id=${this.deleteId}`
       

    this.service.getApi(url,0).subscribe((response:any) => {
      if(response.responseCode == 200){
       this.getFaqList()
        this.service.showSuccess(response['responseMessage'])
        this.spinner.hide();
        $('#delete').modal('hide');
      }
      else{
        this.spinner.hide();
        $('#delete').modal('hide');

        this.service.toastErr(response['responseMessage'])

      }
    })


   

}
blockUnblock(){

    let url = `client/blockUblockClient?_id=${this._id}`
       

    this.service.getApi(url,0).subscribe((response:any) => {
      if(response.responseCode == 200){
       this.getFaqList()
        this.service.showSuccess(response['responseMessage'])
        this.spinner.hide();
        $('#block').modal('hide');
      }
      else{
        this.spinner.hide();
        $('#block').modal('hide');
        this.service.toastErr(response['responseMessage'])

      }
    })


   

}
_id 
stat
changeStatus(_id, status) {
  console.log("_id", _id)
  this.stat = status
  this._id = _id
  $('#block').modal('show');

}

}
