import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from 'src/app/providers/mainService';

@Component({
  selector: 'app-platform-edit',
  templateUrl: './platform-edit.component.html',
  styleUrls: ['./platform-edit.component.css']
})
export class PlatformEditComponent implements OnInit {
  form: FormGroup;
  faqList: any;
  faqId: any;
  ques: any;
  ans : any;
  regex ="^[^\s]+[-a-zA-Z\s]+([-a-zA-Z]+)*$"
  flag : any
  constructor(private activatedroute: ActivatedRoute,private router : Router, public service:MainService, private spinner: NgxSpinnerService) {
    this.activatedroute.queryParams.subscribe((res) => {
      this.faqId = res.itemId;
      this.flag = res.flag
    });
  }

  ngOnInit() {
    let web_side ;
    if(this.flag == 1){
      web_side= 'Organiser' 
    }
    if(this.flag == 0){
      web_side= 'Player' 
    }
    if(this.flag == 2){
      web_side= 'NONE' 
    }

    this.form = new FormGroup({
      "question": new FormControl('',[ Validators.required]),
      "answer": new FormControl('',[ Validators.required]),
      "descriptionView": new FormControl('',[ Validators.required]),

    });
    this.viewFaq(web_side);
  }

viewFaq(web_side){
  let url = `static/viewPlatform?_id=${this.faqId}&web_side=${web_side}`

     this.spinner.show() 
    this.service.getApi(url,1).subscribe((res:any) => {
      if(res.response_code == 200){
        this.form.patchValue({
          "question": res.result.title,
          "answer": res.result.description,
          "descriptionView":res.result.descriptionView
        })
        this.service.showSuccess(res.response_message)
           this.spinner.hide();
      }
      else{
        this.service.toastErr(res.response_message)
           this.spinner.hide();
      }
    })  
}


  updateFaq(){
    let url = `static/editPlatform?_id=${this.faqId}`
    const data = {
  //  "faqId": this.faqId,
   "title": this.form.value.question,
   "description" : this.form.value.answer,
   "descriptionView": this.form.value.descriptionView,
   "userid" : localStorage.getItem('adminId')
    }
    this.spinner.show() 
    this.service.postApi(url,data,1).subscribe((res:any) => {
      if(res.response_code == 200){
        this.service.showSuccess(res.response_message)
           this.spinner.hide();
        this.router.navigate(["/platform-list"],{queryParams:{flag : this.flag}})
      }
      else{
        this.service.toastErr(res.response_message)
           this.spinner.hide();
      }
    })
  }

}
