import { Component, OnInit } from '@angular/core';
import { MainService } from '../../providers/mainService';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {

  customer: any;
  Id: any = '';
  imageUrl: any  =this.service.imageurlPlaceholder
  profileData: any;
  constructor(private service: MainService,
    private spinner: NgxSpinnerService,
    private route: Router) { }

  ngOnInit() {

    this.Id = localStorage.getItem('_id of customer')
    this.viewParticularCustomer()
  }
  //==========================================================VIEW PARTICULAR CUSTOMER API ====================================================================
  viewParticularCustomer() {
    this.spinner.show()
    this.service.getApi(`users/getDetail?_id=${this.Id}`, 1).subscribe(response => {
      this.spinner.hide();
      if (response['responseCode'] == 200) {
        console.log("getData of a employee>>>", response)
        this.customer = response[`result`]
        if (response['result'].image) {
          this.imageUrl = this.customer.image
        } else {
          this.imageUrl = 'assets/img/default_profile_image.png'
        }
      } else {
        this.service.toastErr(response['responseMessage'])
      }
    }, error => {
      this.spinner.hide();
      this.service.toastErr('Something Went Wrong')
    })
  }

  edit() {
    this.route.navigate(['/editUserDetail'])
  }

  cancel() {
    // this.route.navigate(['/dashboard'])
    window.history.back()
  }
}
