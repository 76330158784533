import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { MainService } from '../../../../providers/mainService';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-comprehensive-feature-edit',
  templateUrl: './comprehensive-feature-edit.component.html',
  styleUrls: ['./comprehensive-feature-edit.component.css']
})
export class ComprehensiveFeatureEditComponent implements OnInit {
  email: any
  imageurl: any =this.service.imageurlPlaceholder
  staticVideo: any
  image: any = ''
  id: any
  particularData: any
  url: any;
  currTab: any;
  buttonName: any;
  logoDetail: any;
  img: any;
  logoid: any;
  staticLogo: FormGroup;
  bannerDetail: any;
  staticBanner: FormGroup;
  bannerid: any;
  constructor(
    private service: MainService,
    private router: Router,
    private route: ActivatedRoute,
    private ng2ImgMaxService: Ng2ImgMaxService,
    private spinner: NgxSpinnerService,
  ) {
    this.route.params.subscribe(param => {
      this.id = param.id
      this.logoid = param.id
      this.bannerid = param.id
    })
    //-------------url managed for edit view ------------------//
    this.url = window.location.href;
    if(this.url.includes('comprenhensiveFeatureAddList')){
      this.currTab = 'Add';
      this.buttonName = 'Add';
      // this.getFeature()
      this.form()
    }else if(this.url.includes('comprenhensiveFeatureViewList')){
      this.currTab = 'View';
      this.buttonName = 'View';
      this.getFeature()
      this.form()
    }else if(this.url.includes('comprenhensiveFeatureEditList')) {
      this.currTab = 'edit';
      this.buttonName = 'edit';
      this.getFeature()
      this.form()
    }else if(this.url.includes('homePageViewlogo')) {
      this.currTab = 'logo';
      this.buttonName = 'logo';
      this.logoform()
      this.getLogo()
    }else if(this.url.includes('homePageEditlogo')) {
      this.currTab = 'editlogo';
      this.buttonName = 'editlogo';
      this.logoform()
      this.getLogo()
    }else if(this.url.includes('homePageViewBanner')) {
      this.currTab = 'banner';
      this.buttonName = 'banner';
      this.bannerform()
      this.getBanner()
    }else if(this.url.includes('homePageEditBanner')) {
      this.currTab = 'editbanner';
      this.buttonName = 'editbanner';
      this.bannerform()
      this.getBanner()
    }
  }

  ngOnInit() {
    
  }
//.........................Form Function ...................................
  form() {
    this.staticVideo = new FormGroup({
      title: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      descriptionView: new FormControl(''),


    })
  }
  logoform() {
    this.staticLogo = new FormGroup({
      title: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
    })
  }
  bannerform() {
    this.staticBanner = new FormGroup({
      title: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
    })
  }
  //...........................get Particular Video List...................... 
  getFeature() {
    let apiData = {
      _id: this.id
    }
    this.spinner.show()
      this.service.postApi(`admin/particularComprehensive`, apiData, 1).subscribe(success => {
               this.spinner.hide();
      if (success.responseCode == 200) {
        this.particularData = success.result
        this.staticVideo.controls['title'].setValue(this.particularData.title)
        this.imageurl = this.particularData.image
        this.staticVideo.controls['description'].setValue(this.particularData.description)
        this.staticVideo.controls['descriptionView'].setValue(this.particularData.descriptionView)

      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
           this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
  //...........................Base 64 function...........................................
  onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      let image = event.target.files[0];
      this.ng2ImgMaxService.resizeImage(image, 400, 300).subscribe(result => {
        var reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onload = (event: any) => {
          this.image = new Image();
          this.image.src = event.target.result;
          this.imageurl = event.target.result
        }
      }
      )
    }
  }
    //........................Service  add Function ...........................
    add(data) {
      let ApiData = {
        "image": this.imageurl,
        // "_id": this.id,
        "description": data.description,
        "title": data.title
      }
      console.log(ApiData)
      this.spinner.show()
         this.service.postApiUpload(`admin/addFeature`, ApiData, 1).subscribe(response => {
                this.spinner.hide();
        if (response.responseCode == 200) {
          this.router.navigate(['/comprenhensiveFeatureList'])
        } else {
          this.service.toastErr(response.responseMessage)
        }
      }, error => {
              this.spinner.hide();
        this.service.toastErr("Something Went Wrong");
      })
    }

  //........................Service  Edit Function ...........................
  edit(data) {
    let ApiData = {
      "image": this.imageurl,
      "_id": this.id,
      "description": data.description,
      "descriptionView":data.descriptionView,
      "title": data.title
    }
    
    this.spinner.show()
       this.service.postApiUpload(`admin/comprehensiveEdit`, ApiData, 1).subscribe(response => {
              this.spinner.hide();
      if (response.responseCode == 200) {
        this.router.navigate(['/comprenhensiveFeatureList'])
      } else {
        this.service.toastErr(response.responseMessage)
      }
    }, error => {
            this.spinner.hide();
      this.service.toastErr("Something Went Wrong");
    })
  }
//---------------------------------get logo -------------------------------//
  getLogo() {
    this.spinner.show()
      this.service.getApi('admin/logoListing', 1).subscribe(success => {
      this.spinner.hide();
      if(success.responseCode==200){
      this.logoDetail=success.result[0]
      this.staticLogo.controls['title'].setValue(this.logoDetail.title)
      this.imageurl = this.logoDetail.image
      this.staticLogo.controls['description'].setValue(this.logoDetail.description)
      } else{
        this.spinner.hide();
        this.service.toastErr(success.responseMessage)

      }
    }, error => {
            this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
   //........................... upload image for logo Base 64 function...........................................
   onLogoImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      let image = event.target.files[0];
      this.ng2ImgMaxService.resizeImage(image, 400, 300).subscribe(result => {
        var reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onload = (event: any) => {
          this.image = new Image();
          this.image.src = event.target.result;
          this.imageurl = event.target.result
          // this.staticLogo.controls['file'].setValue(event.target.result)
        }
      }
      )
    }
  }
  //........................... upload image for logo Base 64 function............................
 //........................Service  Edit Logo Function ...........................
 editLogo(data) {
  let ApiData = {
    "image": this.imageurl,
    "_id": this.logoid,
    "description": data.description,
    "title": data.title
  }
  this.spinner.show()
     this.service.postApiUpload(`admin/logoEdit`, ApiData, 1).subscribe(response => {
            this.spinner.hide();
    if (response.responseCode == 200) {
      this.router.navigate(['/homePageContentManagement'])
    } else {
      this.service.toastErr(response.responseMessage)
    }
  }, error => {
          this.spinner.hide();
    this.service.toastErr("Something Went Wrong");
  })
}
 //........................Service  Edit Logo Function ...........................

 //........................Service  Edit Banner Function ...........................
 editBanner(data) {
  let ApiData = {
    "backGroungImage": this.imageurl,
    "_id": this.bannerid,
    "description": data.description,
    "title": data.title
  }

  this.spinner.show()
     this.service.postApiUpload(`admin/editbanner`, ApiData, 1).subscribe(response => {
            this.spinner.hide();
    if (response.responseCode == 200) {
      this.router.navigate(['/homePageContentManagement'])
    } else {
      this.service.toastErr(response.responseMessage)
    }
  }, error => {
          this.spinner.hide();
    this.service.toastErr("Something Went Wrong");
  })
}
 //........................Service  Edit Banner Function ...........................
//---------------------------------get Banner -------------------------------//
getBanner() {
    this.spinner.show()
      this.service.getApi('admin/bannerListing', 1).subscribe(success => {
      this.spinner.hide();
      if(success.responseCode==200){
      this.bannerDetail=success.result[0]
      this.staticBanner.controls['title'].setValue(this.bannerDetail.title)
      this.imageurl = this.bannerDetail.backGroungImage
      this.staticBanner.controls['description'].setValue(this.bannerDetail.description)
      } else{
        this.spinner.hide();
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
            this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
 //........................... upload image for logo Base 64 function...........................................
 onBannerImageChange(event) {
  if (event.target.files && event.target.files[0]) {
    let image = event.target.files[0];
    this.ng2ImgMaxService.resizeImage(image, 400, 300).subscribe(result => {
      var reader = new FileReader();
      reader.readAsDataURL(result);
      reader.onload = (event: any) => {
        this.image = new Image();
        this.image.src = event.target.result;
        this.imageurl = event.target.result
      }
    }
    )
  }
}
//........................... upload image for logo Base 64 function............................

}
