import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from 'src/app/providers/mainService';
declare var $

@Component({
  selector: 'app-platform-list',
  templateUrl: './platform-list.component.html',
  styleUrls: ['./platform-list.component.css']
})
export class PlatformListComponent implements OnInit {
  faq : any = [];
  faqId:any
  viewPlatformList: void;
  flag : any
  constructor(private service: MainService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router:Router) { 
      this.route.queryParams.subscribe((res)=>{
       this.flag = res.flag 
      })
    }

  ngOnInit() {
    this.viewplatform()
  }
   
    // -------view faq-------
  viewplatform(){
    this.viewPlatformList=void[]
    let web_side ;
    if(this.flag == 1){
      web_side= 'Organiser' 
    }
    if(this.flag == 0){
      web_side= 'Player' 
    }
    if(this.flag == 2){
      web_side= 'NONE' 
    }
    let url = `static/listPlatform?web_side=${web_side}`
    this.spinner.show() 
    this.spinner.show()
    this.service.getApi(url,1).subscribe((response:any) =>{
      if(response.response_code == 200){
        this.viewPlatformList=response.result
        this.spinner.hide();
        this.service.showSuccess(response['response_message'])
      }
      else{
        this.spinner.hide();
        
        this.service.toastErr(response['response_message'])
      }
    },(err)=>{
      this.spinner.hide();
      this.service.toastErr(err['response_message'])
    })
  }
  
  
  navigateEditfaq(id){
    this.router.navigate(["/platform-edit"],{queryParams:{itemId:id,flag :this.flag}})
  }
  deleteId:any;
deleteComptition(deleteId){
this.deleteId = deleteId
$('#delete').modal('show');
}
  delete(){
    let url = `static/deletePlatform?_id=${this.deleteId}`
       
    this.service.getApi(url,1).subscribe((response:any) => {
      if(response.response_code == 200){
       this.viewplatform()
        this.service.showSuccess('Data deleted')
        this.spinner.hide();
        $('#delete').modal('hide');
      }
      else{
        this.spinner.hide();
        $('#delete').modal('hide');

      }
    })
}
breakLine(text){
  return  text.slice(0, 10);
}
}
