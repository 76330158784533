import { Routes, RouterModule } from "@angular/router"
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UserDetailsComponent } from './pages/user-details/user-details.component'
import { EditUserDetailComponent } from './pages/edit-user-detail/edit-user-detail.component'
import { BusinessComponent } from "./pages/business/business.component";
import { EditBusinessDetailComponent } from "./pages/edit-business-detail/edit-business-detail.component";
import { ViewBusinessDetailComponent } from "./pages/view-business-detail/view-business-detail.component";
import { ContentManagementComponent } from "./pages/content-management/content-management.component";
import { ContentManagementEditComponent } from "./pages/content-management-edit/content-management-edit.component";
import { ContentManagementViewComponent } from "./pages/content-management-view/content-management-view.component";
import { EventManagementComponent } from "./pages/event-management/event-management.component";
import { TransactionManagementComponent } from "./pages/transaction-management/transaction-management.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { PlayerManagementComponent } from "./pages/player-management/player-management.component";
import { DashboardManagementComponent } from "./pages/dashboard/dashboard-management/dashboard-management.component";
import { CompetitionManagementComponent } from "./pages/competition-management/competition-management.component";
import { CompetitionManagementViewComponent } from "./pages/competition-management/competition-management-view/competition-management-view.component";
import { CompetitionManagementEditComponent } from "./pages/competition-management/competition-management-edit/competition-management-edit.component";
import { MembershipManagementComponent } from "./pages/membership_management/membership-management/membership-management.component";
import { MembershipManagementDetailsComponent } from "./pages/membership_management/membership-management-details/membership-management-details.component";
import { ViewServiceComponent } from "./pages/membership_management/view-service/view-service.component";
import { combineLatest } from "rxjs";
import { ViewServiceDetailsComponent } from "./pages/membership_management/view-service-details/view-service-details.component";
import { VenueManagementComponent } from "./pages/venue-management/venue-management.component";
import { VenueDetailsComponent } from "./pages/venue-management/venue-details/venue-details.component";
import { HomePageContentManagementComponent } from "./pages/homePage_content_management/home-page-content-management/home-page-content-management.component";
import { EditVideoComponent } from "./pages/homePage_content_management/edit-video/edit-video.component";
import { EditFeatureComponent } from "./pages/homePage_content_management/edit-feature/edit-feature.component";
import { EditFeatureListComponent } from "./pages/homePage_content_management/edit-feature-list/edit-feature-list.component";
import { ServiceListComponent } from "./pages/homePage_content_management/service/service-list/service-list.component";
import { ServiceEditComponent } from "./pages/homePage_content_management/service/service-edit/service-edit.component";
import { AddFeatureComponent } from "./pages/homePage_content_management/add-feature/add-feature.component";
import { ComprehensivFeatureComponent } from "./pages/homePage_content_management/comprehensiv-feature/comprehensiv-feature.component";
import { ComprehensiveFeatureEditComponent } from "./pages/homePage_content_management/comprehensiv-feature/comprehensive-feature-edit/comprehensive-feature-edit.component";
import { NewsLaterListComponent } from "./pages/news-later-list/news-later-list.component";
import { SubscriptionListComponent } from "./pages/subscription-list/subscription-list.component";
import { SubscriptionViewComponent } from "./pages/subscription-list/subscription-view/subscription-view.component";
import { ChatComponent } from "./pages/chat/chat.component";
import { ConfigurationalComponent } from "./pages/configurational/configurational.component";
import { SmsComponent } from "./pages/configurational/sms/sms.component";
import { RegistrationComponent } from "./pages/configurational/registration/registration.component";
import { ViewVideoComponent } from "./pages/homePage_content_management/view-video/view-video.component";
import { ServiceViewComponent } from "./pages/homePage_content_management/service/service-view/service-view.component";
import { ContactUsComponent } from "./pages/contact-us/contact-us.component";
import { HelpViewComponent } from './pages/help-view/help-view.component';
import { HelptEditComponent } from './pages/help-edit/help-edit.component';
import { FaqEditComponent } from "./pages/faq-edit/faq-edit.component";
import { FaqAddComponent } from "./pages/faq-add/faq-add.component";
import { PlatformListComponent } from "./pages/platform/platform-list/platform-list.component";
import { PlatformAddComponent } from "./pages/platform/platform-add/platform-add.component";
import { PlatformEditComponent } from "./pages/platform/platform-edit/platform-edit.component";
import {SocialMediaComponent} from './pages/social-media/social-media.component';
import { ClientListComponent } from "./clientModule/client-list/client-list.component";
import { ClientaddComponent } from "./clientModule/clientadd/clientadd.component";
import { ClienteditComponent } from "./clientModule/clientedit/clientedit.component";
import { ClientviewComponent } from "./clientModule/clientview/clientview.component";
import { PartnerListComponent } from "./partnerModule/partner-list/partner-list.component";
import { PartneraddComponent } from "./partnerModule/partneradd/partneradd.component";
import { PartnerviewComponent } from "./partnerModule/partnerview/partnerview.component";
import { BannerListComponent } from "./pages/homePage_content_management/banner/banner-list/banner-list.component";
import { BannerAddComponent } from "./pages/homePage_content_management/banner/banner-add/banner-add.component";
import { HomeBanneraddComponent } from "./homeBanner/homeBanneradd/homeBanneradd.component";
import { HomeBannerListComponent } from "./homeBanner/homeBanner-list/homeBanner-list.component";
import { ComprehensiveFeatureAddComponent } from "./pages/homePage_content_management/comprehensiv-feature/comprehensive-feature-add/comprehensive-feature-add.component";
import { ServiceAddComponent } from "./pages/homePage_content_management/service/service-add/service-add.component";
import { CreateNewsComponent } from './pages/news-later-list/create-news/create-news.component';
import { ViewNewsComponent } from "./pages/news-later-list/view-news/view-news.component";

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: "login", component: LoginComponent },
  { path: "forgotPassword", component: ForgotPasswordComponent },
  { path: "dashboard/:type", component: DashboardComponent },
  { path: "business-management", component: BusinessComponent },
  { path: "userDetails", component: UserDetailsComponent },
  { path: "editUserDetail", component: EditUserDetailComponent },
  { path: "editBusinessDetail", component: EditBusinessDetailComponent },
  { path: "businessDetails", component: ViewBusinessDetailComponent },
  { path: "editBusinessDetail", component: EditBusinessDetailComponent },
  { path: "content-management", component: ContentManagementComponent },
  { path: "content-edit/:role/:type", component: ContentManagementEditComponent },
  { path: "content-view/:role/:type", component: ContentManagementViewComponent },
  { path: "event-management", component: EventManagementComponent },
  { path: "transactions", component: TransactionManagementComponent },
  { path: "reset-password/:token", component: ResetPasswordComponent },
  { path: "player-management", component: PlayerManagementComponent },
  { path: "dashboard", component: DashboardManagementComponent },
  { path: "competitionManagement", component: CompetitionManagementComponent },
  { path: "competitionManagementView/:id", component: CompetitionManagementViewComponent },
  { path: "competitionManagementEdit", component: CompetitionManagementEditComponent },
  { path: "membershipManagement", component: MembershipManagementComponent },
  { path: "membershipManagementDetails/:id", component: MembershipManagementDetailsComponent },
  { path: "membershipViewService/:id", component: ViewServiceComponent },
  { path: "membershipViewServiceDetails/:membershipId/:serviceId", component: ViewServiceDetailsComponent },
  { path: "venueManagement", component: VenueManagementComponent },
  { path: "venueDetails/:id", component: VenueDetailsComponent },
  { path: "homePageContentManagement", component: HomePageContentManagementComponent },
  { path: "homePageEditVideo", component: EditVideoComponent },
  { path: "homePageEditBanner", component: EditVideoComponent },
  { path: "homePageEditFeature/:id", component: EditFeatureComponent },
  { path: "homePageEditFeatureList", component: EditFeatureListComponent },
  { path: "homePageServiceList", component: ServiceListComponent },
  { path: "homePageServiceEdit/:id", component: ServiceEditComponent },
  { path: "homePageAddFeature", component: AddFeatureComponent },
  { path: "comprenhensiveFeatureList", component: ComprehensivFeatureComponent },
  { path: "comprenhensiveFeatureViewList/:id", component: ComprehensiveFeatureEditComponent },
  { path: "comprenhensiveFeatureEditList/:id", component: ComprehensiveFeatureEditComponent },
  { path: "comprenhensiveFeatureAddList", component: ComprehensiveFeatureEditComponent },
  { path: 'comprehensiveFeatureAdd', component:ComprehensiveFeatureAddComponent},
  { path: "newsLater", component:NewsLaterListComponent },
  { path:"subscriptionList",component:SubscriptionListComponent},
  { path:"subscriptionView/:type",component:SubscriptionViewComponent},
  { path:"chat",component:ChatComponent},
  { path:"configurational",component:ConfigurationalComponent},
  { path:"SMS/:type",component:SmsComponent},
  { path:"registration/:type",component:RegistrationComponent},
  { path: "homePageViewVideo", component: ViewVideoComponent },
  { path: "homePageViewlogo", component: ComprehensiveFeatureEditComponent },
  { path: "homePageEditlogo/:id", component: ComprehensiveFeatureEditComponent },
  { path: "homePageViewBanner", component: ComprehensiveFeatureEditComponent },
  { path: "homePageEditBanner/:id", component: ComprehensiveFeatureEditComponent },
  { path: "homePageViewBanner", component: ViewVideoComponent },
  { path: "homePageListBanner", component:BannerListComponent},
  { path: "homePageAddBanner", component: BannerAddComponent},
  { path: "homePageServiceView/:id", component: ServiceViewComponent },
  { path: "contact-us", component: ContactUsComponent},
  { path: "help-view", component: HelpViewComponent},
  { path: "help-edit", component: HelptEditComponent},
  { path: "faq-edit", component:FaqEditComponent},
  { path: "faq-add", component:FaqAddComponent},


  { path: "client-list", component: ClientListComponent},
  { path: "client-edit", component: ClientaddComponent},
  { path: "client-add", component:ClientaddComponent},
  { path: "client-view", component:ClientviewComponent},
  
  
  
  { path: "partner-list", component: PartnerListComponent},
  { path: "partner-edit", component: PartneraddComponent},
  { path: "partner-add", component:PartneraddComponent},
  { path: "partner-view", component:PartnerviewComponent},
  // platform
  { path: 'platform-list', component:PlatformListComponent},
  { path: 'platform-add', component:PlatformAddComponent},
  { path : 'platform-edit', component:PlatformEditComponent},
  { path : 'social-media', component:SocialMediaComponent},

  { path : 'banner-add', component:HomeBanneraddComponent},
  { path : 'banner-list', component:HomeBannerListComponent},
  { path: 'module-add' , component: ServiceAddComponent},
  { path:'create-news',component:CreateNewsComponent},
  { path :'view-news' , component:ViewNewsComponent},



  



]