import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from 'src/app/providers/mainService';
declare var $
@Component({
  selector: 'app-homeBanner-list',
  templateUrl: './homeBanner-list.component.html',
  styleUrls: ['./homeBanner-list.component.css']
})
export class HomeBannerListComponent implements OnInit {



  faq : any = [];

  constructor(private service: MainService,

    private spinner: NgxSpinnerService,
 
    private router:Router) {
  
    
     }

  ngOnInit() {

       this.getFaqList()
  }


  goBack() {
    window.history.back()
  }




   // ------- get FAQ list -------- //
   getFaqList() {
    let url = "homeAllBanner/homeAllBannerList"
       this.spinner.show()
       let data = {}

    this.service.postApi(url,data,0).subscribe((response:any) => {
      if(response.responseCode == 200){
        this.faq = response.result;
        this.service.showSuccess(response['responseMessage'])
        this.spinner.hide();
      }
      else{
        this.spinner.hide();
        this.service.toastErr(response['responseMessage'])

      }
    })
}
checkValidBaneer(type){
   if(type=='1'){
    return 'Home Page 1st Banner'
   }
   if(type=='2'){
    return 'Home Page 2nd Banner'
   }if(type=='3'){
    return 'Home Page 3rd Banner'
   }if(type=='4'){
    return 'Home Page 4th Banner'
   }if(type=='5'){
    return 'Home Page 5th Banner'
   }
   if(type=='6'){
    return 'Other paegs Banner (2000 x 900 px)'
   }
}




navigateEditfaq(id){
  this.router.navigate(["/banner-add"],{queryParams:{itemId:id}})
}
changePage($event){
      this.getFaqList()
}

deleteId:any;
deleteComptition(deleteId){
this.deleteId = deleteId
$('#delete').modal('show');
}
delete(){
    let url = `homeAllBanner/deleteHomeBanner?_id=${this.deleteId}`
    this.service.getApi(url,0).subscribe((response:any) => {
      if(response.responseCode == 200){
       this.getFaqList()
        this.service.showSuccess(response['responseMessage'])
        this.spinner.hide();
        $('#delete').modal('hide');
      }
      else{
        this.spinner.hide();
        $('#delete').modal('hide');

        this.service.toastErr(response['responseMessage'])

      }
    })


   

}
blockUnblock(){

    let url = `client/blockUblockClient?_id=${this._id}`
       

    this.service.getApi(url,0).subscribe((response:any) => {
      if(response.responseCode == 200){
       this.getFaqList()
        this.service.showSuccess(response['responseMessage'])
        this.spinner.hide();
        $('#block').modal('hide');
      }
      else{
        this.spinner.hide();
        $('#block').modal('hide');
        this.service.toastErr(response['responseMessage'])

      }
    })


   

}
_id 
stat
changeStatus(_id, status) {
  console.log("_id", _id)
  this.stat = status
  this._id = _id
  $('#block').modal('show');

}

}
