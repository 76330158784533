import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MyDatePickerModule } from "mydatepicker";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./pages/login/login.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { routes } from "./app.routing";
import { MainService } from "./providers/mainService";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { NgxPaginationModule } from "ngx-pagination";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { UserDetailsComponent } from "./pages/user-details/user-details.component";
import { EditUserDetailComponent } from "./pages/edit-user-detail/edit-user-detail.component";
import { BusinessComponent } from "./pages/business/business.component";
import { EditBusinessDetailComponent } from "./pages/edit-business-detail/edit-business-detail.component";
import { CKEditorModule } from "ng2-ckeditor";
import { SideMenuComponent } from "./pages/side-menu/side-menu.component";
import { ViewBusinessDetailComponent } from "./pages/view-business-detail/view-business-detail.component";
import { ContentManagementComponent } from "./pages/content-management/content-management.component";
import { ContentManagementEditComponent } from "./pages/content-management-edit/content-management-edit.component";
import { ContentManagementViewComponent } from "./pages/content-management-view/content-management-view.component";
import { CookieService } from "ngx-cookie-service";
import { EventManagementComponent } from "./pages/event-management/event-management.component";
import { TransactionManagementComponent } from "./pages/transaction-management/transaction-management.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { PlayerManagementComponent } from "./pages/player-management/player-management.component";
import { Ng2FileSizeModule } from "ng2-file-size";
import { DashboardManagementComponent } from "./pages/dashboard/dashboard-management/dashboard-management.component";
import { CompetitionManagementComponent } from "./pages/competition-management/competition-management.component";
import { CompetitionManagementViewComponent } from "./pages/competition-management/competition-management-view/competition-management-view.component";
import { CompetitionManagementEditComponent } from "./pages/competition-management/competition-management-edit/competition-management-edit.component";
import { MembershipManagementComponent } from "./pages/membership_management/membership-management/membership-management.component";
import { MembershipManagementDetailsComponent } from "./pages/membership_management/membership-management-details/membership-management-details.component";
import { ViewServiceComponent } from "./pages/membership_management/view-service/view-service.component";
import { ViewServiceDetailsComponent } from "./pages/membership_management/view-service-details/view-service-details.component";
import { VenueManagementComponent } from "./pages/venue-management/venue-management.component";
import { VenueDetailsComponent } from "./pages/venue-management/venue-details/venue-details.component";
import { HomePageContentManagementComponent } from "./pages/homePage_content_management/home-page-content-management/home-page-content-management.component";
import { EditVideoComponent } from "./pages/homePage_content_management/edit-video/edit-video.component";
import { EditFeatureComponent } from "./pages/homePage_content_management/edit-feature/edit-feature.component";
import { Ng2ImgMaxModule } from "ng2-img-max";
import { EditFeatureListComponent } from "./pages/homePage_content_management/edit-feature-list/edit-feature-list.component";
import { ServiceListComponent } from "./pages/homePage_content_management/service/service-list/service-list.component";
import { ServiceEditComponent } from "./pages/homePage_content_management/service/service-edit/service-edit.component";
import { AddFeatureComponent } from "./pages/homePage_content_management/add-feature/add-feature.component";
import { ComprehensivFeatureComponent } from "./pages/homePage_content_management/comprehensiv-feature/comprehensiv-feature.component";
import { ComprehensiveFeatureEditComponent } from "./pages/homePage_content_management/comprehensiv-feature/comprehensive-feature-edit/comprehensive-feature-edit.component";
import { NewsLaterListComponent } from "./pages/news-later-list/news-later-list.component";
import { SubscriptionListComponent } from "./pages/subscription-list/subscription-list.component";
import { SubscriptionViewComponent } from "./pages/subscription-list/subscription-view/subscription-view.component";
import { ChatComponent } from "./pages/chat/chat.component";
import { ConfigurationalComponent } from "./pages/configurational/configurational.component";
import { SmsComponent } from "./pages/configurational/sms/sms.component";
import { RegistrationComponent } from "./pages/configurational/registration/registration.component";
import { ViewVideoComponent } from "./pages/homePage_content_management/view-video/view-video.component";
import { ServiceViewComponent } from "./pages/homePage_content_management/service/service-view/service-view.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgxSpinnerModule } from "ngx-spinner";
import { RatingModule } from "ngx-rating";
import { ContactUsComponent } from "./pages/contact-us/contact-us.component";
import { HelpViewComponent } from "./pages/help-view/help-view.component";
import { HelptEditComponent } from "./pages/help-edit/help-edit.component";
import { FaqEditComponent } from "./pages/faq-edit/faq-edit.component";
import { FaqAddComponent } from "./pages/faq-add/faq-add.component";
import { PlatformListComponent } from "./pages/platform/platform-list/platform-list.component";
import { PlatformAddComponent } from "./pages/platform/platform-add/platform-add.component";
import { PlatformEditComponent } from "./pages/platform/platform-edit/platform-edit.component";
import { SocialMediaComponent } from "./pages/social-media/social-media.component";
// import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
// import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonComponent } from "./pages/share-button/share-button.component";
import { CeiboShare } from "ng2-social-share";
import { ClientListComponent } from './clientModule/client-list/client-list.component';
import { ClientaddComponent } from './clientModule/clientadd/clientadd.component';
import { ClienteditComponent } from './clientModule/clientedit/clientedit.component';
import { ClientviewComponent } from './clientModule/clientview/clientview.component';
import { PartnerListComponent } from "./partnerModule/partner-list/partner-list.component";
import { PartneraddComponent } from "./partnerModule/partneradd/partneradd.component";
import { PartnereditComponent } from "./partnerModule/partneredit/partneredit.component";
import { PartnerviewComponent } from "./partnerModule/partnerview/partnerview.component";
import { BannerListComponent } from "./pages/homePage_content_management/banner/banner-list/banner-list.component";
import { BannerAddComponent } from "./pages/homePage_content_management/banner/banner-add/banner-add.component";
import { HomeBanneraddComponent } from "./homeBanner/homeBanneradd/homeBanneradd.component";
import { HomeBannerListComponent } from "./homeBanner/homeBanner-list/homeBanner-list.component";
import { ComprehensiveFeatureAddComponent } from "./pages/homePage_content_management/comprehensiv-feature/comprehensive-feature-add/comprehensive-feature-add.component";
import { ServiceAddComponent } from "./pages/homePage_content_management/service/service-add/service-add.component";
import { CreateNewsComponent } from './pages/news-later-list/create-news/create-news.component';
import { ViewNewsComponent } from "./pages/news-later-list/view-news/view-news.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    UserDetailsComponent,
    EditUserDetailComponent,
    BusinessComponent,
    EditBusinessDetailComponent,
    SideMenuComponent,
    ViewBusinessDetailComponent,
    ContentManagementComponent,
    ContentManagementEditComponent,
    ContentManagementViewComponent,
    EventManagementComponent,
    TransactionManagementComponent,
    ResetPasswordComponent,
    PlayerManagementComponent,
    DashboardManagementComponent,
    CompetitionManagementComponent,
    CompetitionManagementViewComponent,
    CompetitionManagementEditComponent,
    MembershipManagementComponent,
    MembershipManagementDetailsComponent,
    ViewServiceComponent,
    ViewServiceDetailsComponent,
    VenueManagementComponent,
    VenueDetailsComponent,
    HomePageContentManagementComponent,
    EditVideoComponent,
    EditFeatureComponent,
    EditFeatureListComponent,
    ServiceListComponent,
    ServiceEditComponent,
    AddFeatureComponent,
    ComprehensivFeatureComponent,
    ComprehensiveFeatureEditComponent,
    ComprehensiveFeatureAddComponent,
    NewsLaterListComponent,
    SubscriptionListComponent,
    SubscriptionViewComponent,
    ChatComponent,
    ConfigurationalComponent,
    SmsComponent,
    RegistrationComponent,
    ViewVideoComponent,
    ServiceViewComponent,
    ContactUsComponent,
    HelptEditComponent,
    HelpViewComponent,
    FaqEditComponent,
    FaqAddComponent,
    PlatformListComponent,
    PlatformAddComponent,
    PlatformEditComponent,
    SocialMediaComponent,
    ShareButtonComponent,
    CeiboShare,
    ClientListComponent,
    ClientaddComponent,
    ClienteditComponent,
    ClientviewComponent,
    PartnerListComponent,
    PartneraddComponent,
    PartnereditComponent,
    PartnerviewComponent,
    BannerListComponent,
    BannerAddComponent,
    HomeBanneraddComponent,
    HomeBannerListComponent,
    ServiceAddComponent,
    CreateNewsComponent,
    ViewNewsComponent
  ],

  imports: [
    BrowserModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      progressAnimation: "decreasing",
      progressBar: true,
    }),
    RouterModule.forRoot(routes),
    FormsModule,
    RatingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    MyDatePickerModule,
    Ng2ImgMaxModule,
    Ng2FileSizeModule,

    //     ShareButtonsModule.withConfig({
    //   debug: true
    // }),
    // ShareIconsModule,
    NgMultiSelectDropDownModule.forRoot(),
    CKEditorModule,
  ],

  providers: [MainService, CookieService],
  bootstrap: [AppComponent],
})
export class AppModule {}
